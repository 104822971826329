<!--
 * @Author: your name
 * @Date: 2020-09-10 11:30:44
 * @LastEditTime: 2020-09-27 11:21:11
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\coupon\dialog\addDialog.vue
-->
<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '编辑' : '新增'}优惠券`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="720px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="优惠券名称" prop="couponName">
              <el-input
                v-model="form.couponName"
                placeholder="请输入优惠券名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="适用场馆" prop="venueId">
              <el-select v-model="form.venueId" @change="getSceneList" @clear="getSceneList" clearable="" style="width:100%">
                  <el-option v-for="(item, index) in venueList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <!-- 赛事 -->
          <el-col :span="12">
            <el-form-item label="场景类型" prop="sceneType">
              <el-select v-model="form.sceneType" @change="getSceneList" @clear="getSceneList" clearable="" style="width:100%">
                  <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_SCENE_TYPE" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="showScene">
            <el-form-item label="适用场景" prop="scene">
              <el-select v-model="form.scene" clearable="" style="width:100%">
                  <el-option v-for="(item, index) in sceneList" :key="index" :label="item.name" :value="item.code"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="可领取上限" prop="upperLimit">
               <el-input-number
                v-model="form.upperLimit"
                :controls="false"
                :min="1"
                placeholder="请填写可领取上限"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优惠类型" prop="couponType">
              <el-select v-model="form.couponType" clearable="" style="width:100%">
                <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_COUPON_TYPE" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="有效期" prop="date">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                @change="dateChange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用金额要求" prop="amountRequired">
               <el-input-number
                v-model="form.amountRequired"
                :controls="false"
                :min="0"
                placeholder="请输入使用金额要求"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="优惠折数/金额" prop="discountAmount">
               <el-input-number
                v-model="form.discountAmount"
                :controls="false"
                :min="0"
                placeholder="请输入优惠折数/金额"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="使用规则" prop="rules">
               <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入使用规则，多条用分号（ ; ）隔开"
                v-model="form.rules">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    venueList: {
      required: true,
    },
  },
  data() {
    return {
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      sceneList: [], //适用场景列表 根据适用场馆和场景类型获取
      showScene: false,
      addFlag: true,
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new AddDialogClass('form')
      console.log('我初始化了')
    },
    formatDate(date) {
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let minute = date.getMinutes();
      minute = minute < 10 ? `0${minute}` : minute;
      let second = date.getSeconds();
      second = second < 10 ? `0${second}` : second;
      return `${y}-${m}-${d} ${h}:${minute}:${second}`
    },
    dateChange(val) {
      if (val) {
        this.form.validDateFrom = this.formatDate(val[0])
        this.form.validDateTo = this.formatDate(val[1])
        return
      }
      this.form.validDateFrom = ''
      this.form.validDateTo = ''
    },
    /**
     * @description: 获取适用场景下拉列表
     * @param {type}
     * @return {type}
     */
    getSceneList(val, scene = '') {
      this.form.scene = scene
      this.showScene = true
      if ((this.form.sceneType === '0' && this.form.venueId === '0') || this.form.sceneType === '2') {
        this.sceneList = []
        this.showScene = false
        return;
      }
      if (this.form.sceneType && this.form.venueId) {
        this.$http.get(`${apis.getSceneList}?applicableVenue=${this.form.venueId}&sceneType=${this.form.sceneType}`).then((res) => {
          if (res.data.code === 0) {
            this.sceneList = res.data.data
          }
        })
      } else {
        this.sceneList = []
      }
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log(this.isEdit)
          if (this.isEdit) {
            this.$http.put(apis.updateCoupon, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog()
              }
            })
          } else {
            delete this.form.id
            if (this.addFlag) {
              this.addFlag = false
              this.$http.post(apis.saveCoupon, this.form).then((res) => {
                this.addFlag = true
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.closeDialog()
                }
              })
            }
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    formatDateTime(val) {
      const date = val.split(' ')[0].split('-')
      const time = val.split(' ')[1].split(':')
      const y = date[0]
      const m = date[1]
      const d = date[2]
      const hh = time[0]
      const mm = time[1]
      const ss = time[2]
      return {
        y, m, d, hh, mm, ss,
      }
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(id) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.getCouponDetail}/${id}`).then((res) => {
        this.form = res.data
        const validDateFrom = this.formatDateTime(res.data.validDateFrom)
        const validDateTo = this.formatDateTime(res.data.validDateTo)
        this.form.date = [new Date(validDateFrom.y, validDateFrom.m, validDateFrom.d, validDateFrom.hh, validDateFrom.mm, validDateFrom.ss), new Date(validDateTo.y, validDateTo.m, validDateTo.d, validDateTo.hh, validDateTo.mm, validDateTo.ss)]
        if ((this.form.sceneType === '0' && this.form.venueId === '0') || this.form.sceneType === '2') {
          this.showScene = false
        } else {
          this.getSceneList('', res.data.scene)
          // this.showScene = true
        }
      })
    },
  },
}
</script>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
<style lang="less" scoped>
/deep/.el-input-number .el-input__inner{
  text-align: left!important;
}
</style>
