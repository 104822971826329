<!--
 * @Author: 林娴
 * @LastEditors: 林娴
-->
<template>
  <div class="wrapper">
      <el-dialog
      title="优惠券详情"
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="720px"
    >
      <div class="search-container">
        <el-row>
          <el-col :span="19">
            <el-form ref="form" :model="form" :inline="true">
              <el-form-item label="手机号" class="form-item">
                <el-input clearable v-model="form.telephoneNum" style="width:160px" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item label="使用状态" class="form-item">
                <el-select v-model="form.status" clearable="" style="width:150px">
                  <el-option v-for="(item, index) in DROPDOWNBOX.coupon_use_status" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col class="search-btn-con" :span="5">
            <el-button type="primary" @click="searchData" class="search-button">{{$t('search')}}</el-button>
            <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          :height="tableHeight"
          style="width: 100%"
          border
          @sort-change='sort_post_change'
          :row-class-name="tableRowClassName">
          <el-table-column
            type="index"
            align="center"
            fixed="left"
            label="序号"
            width="55">
          </el-table-column>
          <el-table-column
            prop="id"
            align="center"
            label="优惠券码"
            show-overflow-tooltip
            width="170">
          </el-table-column>
          <el-table-column
            prop="userName"
            align="center"
            width="140"
            label="绑定用户"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="telephoneNumber"
            align="center"
            width="150"
            label="手机号"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="status"
            align="center"
            width="150"
            label="使用状态"
            show-overflow-tooltip>
          </el-table-column>
        </el-table>
      </div>
      <div class="page-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DetailClass from './detail'
import dialogMixin from '@/mixins/dialogMixin'
import mixin from '@/mixins/index'
import apis from '@/apis'

export default {
  components: {},
  mixins: [dialogMixin, mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      id: '',
      form: new DetailClass('form'), // 实例化一个表单的变量
      venueList: '',
      tableData: [],
    };
  },
  watch: {},
  computed: {
    ...mapState(['Language', 'TOKEN', 'DROPDOWNBOX']),
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    getDataQuery(id) {
      console.log(id)
      this.id = id
      this.searchData()
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.getCouponInfoList, { ...this.form, couponId: this.id }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new DetailClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper{
    .search-button,.clean-button{
      width: 60px;
      height: 35px;
      text-align: center;
      padding: 0;
    }
    .page-container{
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }
}
</style>
