<template>
  <div class="wrapper">
      <el-dialog
      title="分享"
      :visible.sync="show"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="400px"
    >
        <img :src="erweimaPath" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    erweimaPath: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
    };
  },
  watch: {},
  computed: {},
  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper{
    img{
        width: 350px;
        height: 350px;
    }
}
</style>
