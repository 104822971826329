/*
 * @Author: 林娴
 * @Date: 2020-09-10 11:30:44
 * @LastEditTime: 2020-09-24 17:56:10
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-arc-view\src\views\coupon\dialog\addDialogClass.js
 */

class searchFrom {
  validateFn = (rule, value, callback) => {
    if (value) {
      if (value.length > 50) {
        callback([new Error('字数超出限制')]);
      } else {
        callback();
      }
    } else {
      callback([new Error('请输入优惠券名称')]);
    }
  }

  constructor(type) {
    if (type === 'form') {
      this.id = ''
      this.venueId = ''
      this.sceneType = ''
      this.scene = ''
      this.upperLimit = undefined
      this.couponType = ''
      this.date = ''
      this.amountRequired = undefined
      this.discountAmount = undefined
      this.rules = ''
      this.couponName = ''
    } else if (type === 'rule') {
      this.couponName = [{ required: true, validator: this.validateFn, trigger: 'blur' }]
      this.upperLimit = [{ required: true, message: '请输入领取上限', trigger: 'blur' }]
      this.amountRequired = [{ required: true, message: '请输入使用金额要求', trigger: 'blur' }]
      this.discountAmount = [{ required: true, message: '请输入优惠折数/金额', trigger: 'blur' }]
      this.date = [{ required: true, message: '请选择有效期', trigger: 'change' }]
      this.couponType = [{ required: true, message: '请选择优惠券类型', trigger: 'change' }]
      this.scene = [{ required: true, message: '请选择适用场景', trigger: 'change' }]
      this.sceneType = [{ required: true, message: '请选择场景类型', trigger: 'change' }]
      this.venueId = [{ required: true, message: '请选择适用场馆', trigger: 'change' }]
    }
  }
}
export default searchFrom
