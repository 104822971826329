<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" :inline="true">
            <div>
                <el-form-item label="优惠券名称" class="form-item">
                  <el-input clearable v-model="form.couponName" placeholder="请输入优惠券名称"></el-input>
                </el-form-item>
                <el-form-item label="适用场馆" class="form-item">
                  <el-select v-model="form.applicableVenue" @change="getSceneList" @clear="getSceneList" clearable="" style="width:100%">
                    <el-option v-for="(item, index) in venueList" :key="index" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="场景类型" prop="sceneType">
                  <el-select v-model="form.sceneType" @change="getSceneList" @clear="getSceneList" clearable="" style="width:100%">
                    <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_SCENE_TYPE" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="适用场景" class="form-item">
                  <el-select v-model="form.scene" @change="sceneChange" clearable="" style="width:100%">
                    <el-option v-for="(item, index) in sceneList" :key="index" :label="item.name" :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="优惠类型" class="form-item">
                  <el-select v-model="form.couponType" clearable="" style="width:100%">
                    <el-option v-for="(item, index) in DROPDOWNBOX.VENUE_COUPON_TYPE" :key="index" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="有效期" class="form-item">
                  <el-date-picker
                    v-model="form.date"
                    type="datetimerange"
                    @change="dateChange"
                    :picker-options="pickerOptions"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    align="right">
                  </el-date-picker>
                </el-form-item>
            </div>
          </el-form>
        </el-col>
        <el-col class="search-btn-con" :span="4">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        @sort-change='sort_post_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column
					type="selection"
          align="center"
          fixed="left"
					width="55">
				</el-table-column>
        <el-table-column
					type="index"
          align="center"
          fixed="left"
          label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					prop="couponName"
          align="center"
					label="优惠券名称"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="venueName"
          align="center"
          width="220"
					label="适用场馆"
          sortable="custom"
          show-overflow-tooltip>
				</el-table-column>
				<el-table-column
					prop="sceneType"
          align="center"
					label="场景类型"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="matchName"
          align="center"
					label="适用场景"
					width="160">
				</el-table-column>
				<el-table-column
					prop="receivedQty"
          align="center"
					label="已领取数量"
          sortable='custom'
					width="180">
				</el-table-column>
				<el-table-column
					prop="useQty"
          align="center"
					label="已使用数量"
          sortable='custom'
					width="180">
				</el-table-column>
        <el-table-column
          align="center"
					prop="upperLimit"
          sortable='custom'
          width="220"
					label="可领取上限">
				</el-table-column>
        <el-table-column
					prop="cancelQty"
          align="center"
					label="取消订单数量"
          sortable='custom'
					width="180">
				</el-table-column>
        <el-table-column
					prop="expiredQty"
          align="center"
					label="过期数量"
          sortable='custom'
					width="180">
				</el-table-column>
        <el-table-column
					prop="validDateFrom"
          align="center"
					label="有效期起"
          sortable='custom'
					width="180">
				</el-table-column>
        <el-table-column
					prop="validDateTo"
          align="center"
					label="有效期止"
          sortable='custom'
					width="180">
				</el-table-column>
        <el-table-column
					prop="rules"
          align="center"
					label="使用规则"
          show-overflow-tooltip
					width="180">
				</el-table-column>
        <el-table-column
					prop="couponType"
          align="center"
					label="优惠类型"
          show-overflow-tooltip
					width="160">
        </el-table-column>
        <el-table-column
					prop="amountRequired"
          align="center"
					label="使用金额要求"
          show-overflow-tooltip
					width="180">
				</el-table-column>
        <el-table-column
					prop="discountAmount"
          align="center"
					label="优惠折数/金额"
          show-overflow-tooltip
					width="200">
        </el-table-column>
        <el-table-column
					prop="createTime"
          align="center"
					label="创建时间"
          show-overflow-tooltip
					width="180">
				</el-table-column>
        <el-table-column
					prop="createBy"
          align="center"
					label="创建人"
          show-overflow-tooltip
					width="180">
				</el-table-column>
        <el-table-column
          align="center"
          width="320px"
          fixed="right"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" @click="changeDetailDialog(true, true, scope.row)">查看详情</el-button>
            <el-button class="default-button"  @click="shareImg(scope.row)">分享</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="bottom-con">
      <div class="button-container">
        <el-button class="default-button" style="margin-left: 10px" @click="openDeleteDialog(selectedArr.map(item => item.id))">{{$t('delete')}}</el-button>
        <el-button class="default-button" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
      </div>
      <div class="page-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :venueList="venueList" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 查看详情 -->
    <detail-dialog ref="detailDialog" :Language="Language" :show="detailDialog.visible" :isEdit="detailDialog.isEdit" @closeDialog="changeDetailDialog"></detail-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 二维码弹框 -->
    <img-dialog ref="imgDialog" :Language="Language" :show="imgDialog.visible" :erweimaPath="erweimaPath" @closeDialog="changeImgDialog"></img-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UserSearchClass from './userSearchClass'
import apis from '@/apis'
import mixin from '@/mixins/index'
import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'
import imgDialog from "./dialog/erweima.vue";
import detailDialog from "./dialog/detailDialog.vue";

export default {
  components: {
    addDialog,
    detailDialog,
    confirmDialog,
    imgDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      venueList: [],
      sceneList: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      detailDialog: {
        visible: false,
        isEdit: false,
      },
      imgDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      url: '',
      erweimaPath: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
    }
  },
  computed: {
    ...mapState(['Language', 'TOKEN', 'DROPDOWNBOX']),
  },
  activated() {
    this.getVenueList()
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.getCouponList, { ...this.form }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @description: 获取适用场馆下拉列表
     * @param {type}
     * @return {type}
     */
    getVenueList() {
      this.$http.get(apis.getVenueList).then((res) => {
        if (res.data.code === 0) {
          this.venueList = res.data.data
        }
      })
    },
    /**
     * @description: 获取适用场景下拉列表
     * @param {type}
     * @return {type}
     */
    getSceneList() {
      this.form.scene = undefined
      this.sceneList = []
      if ((this.form.sceneType === '0' && this.form.applicableVenue === '0') || this.form.sceneType === '2') {
        return;
      }
      if (this.form.sceneType && this.form.applicableVenue) {
        this.$http.get(`${apis.getSceneList}?applicableVenue=${this.form.applicableVenue}&sceneType=${this.form.sceneType}`).then((res) => {
          if (res.data.code === 0) {
            this.sceneList = res.data.data
          }
        })
      }
    },
    sceneChange(val) {
      this.form.scene = val
      this.$forceUpdate()
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    formatDate(date) {
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? `0${m}` : m;
      let d = date.getDate();
      d = d < 10 ? `0${d}` : d;
      let h = date.getHours();
      h = h < 10 ? `0${h}` : h;
      let minute = date.getMinutes();
      minute = minute < 10 ? `0${minute}` : minute;
      let second = date.getSeconds();
      second = second < 10 ? `0${second}` : second;
      return `${y}-${m}-${d} ${h}:${minute}:${second}`
    },
    dateChange(val) {
      if (val) {
        this.form.validDateFrom = this.formatDate(val[0])
        this.form.validDateTo = this.formatDate(val[1])
        return
      }
      this.form.validDateFrom = ''
      this.form.validDateTo = ''
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data.id)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    //查看详情
    changeDetailDialog(dialogStatus, isEdit = true, data = {}) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.detailDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['detailDialog'].getDataQuery(data.id)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.detailDialog.visible = dialogStatus
      })
    },
    // 二维码弹框
    shareImg(data) {
      if (!data.shareCode) {
        this.$message.warning('分享码生成失败！')
        return
      }
      this.$http.get(`${apis.getCouponStatus}/${data.id}`).then((res) => {
        if (res.data.code === 0 && res.data.data) {
          this.changeImgDialog(true, data.shareCode)
        }
      })
    },
    changeImgDialog(dialogStatus, erweimaPath = '') {
      if (dialogStatus) {
        this.erweimaPath = erweimaPath
      }
      this.$nextTick(() => {
        this.imgDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.delete(`${apis.deleteCoupon}?ids=${this.confirmDialog.data.ids.toString()}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../assets/styles/mixin.scoped.less";
.form-item{
  margin-right: 30px;
}
.bottom-con{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  .button-container{
    width: auto;
  }
}
.search-btn-con{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
<style lang="less">
@import "../../assets/styles/mixin.less";
</style>
